<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <div>
        <h1>Hunde</h1>
        <p>
          Wir haben derzeit neun spanische Mastiff-Hunde. Sie bewachen das Gelände und nehmen ihre Aufgabe sehr ernst.
        </p>
        <p>
          Acht von ihnen sind die Welpen einer Hündin, die über ein paar Wochen immer wieder hier herumlief, bis wir sie
          fütterten. Kurze Zeit später brachte sie einen Wurf von zehn Welpen zur Welt, von denen zwei nicht überlebten.
          Wahrscheinlich war sie nicht in der Lage, alle zehn mit Milch zu versorgen.
        </p>
        <picture>
          <source media="(max-width: 500px)" srcset="../../assets/images/sm-miriamDog.jpg">
          <img src="../../assets/images/miriamDog.jpg">
        </picture>

        <p>
          Alle acht Hunde sind groß und schwer. Sie wiegen jeweils zwischen 40 kg und 50 kg. Das Rudel macht
          normalerweise alles gemeinsam und wer auch immer hier eindringt, hat bald alle acht von ihnen am Hals. Sie
          sind sehr territorial.
        </p>

        <picture>
          <source media="(max-width: 500px)" srcset="../../assets/images/sm-dogsLinedUp.jpg">
          <img src="../../assets/images/dogsLinedUp.jpg">
        </picture>


        <p>
          Wikipedia hat mehr Details über diese Hunderasse: <a target="_blank"
            href="https://de.wikipedia.org/wiki/Mast%C3%ADn_Espa%C3%B1ol">Mastín Español</a>.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/views/concept/Sidebar.vue';

export default {
  name: "DogsDE",
  components: {
    Sidebar,
  },

};
</script>
